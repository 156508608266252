import React, { useEffect, useState, useContext } from "react";
import propTypes from "prop-types";

import Context from "../../ContextProvider";
import Empfehlungen from "./Empfehlungen";

import {
  Select,
  TextField,
  DateField,
  Range,
  CheckboxNormal,
  Checkbox,
} from "./Inputs";

function FormQuestion({ question, answerQuestion, value, index }) {
  const { type, label } = question;

  switch (type) {
    case "date":
      return (
        <DateField
          question={question}
          value={value || ""}
          setValue={(answer) => {
            answerQuestion(label, answer);
          }}
          index={index}
        />
      );
    case "select":
      return (
        <Select
          question={question}
          value={value}
          setValue={(answer) => {
            answerQuestion(label, answer);
          }}
          index={index}
        />
      );
    case "text":
      return (
        <TextField
          question={question}
          value={value || ""}
          setValue={(answer) => {
            answerQuestion(label, answer);
          }}
          index={index}
        />
      );
    case "range":
      return (
        <Range
          question={question}
          value={value}
          setValue={(answer) => {
            answerQuestion(label, answer);
          }}
          index={index}
        />
      );
    case "checkbox-normal":
      return (
        <CheckboxNormal
          question={question}
          value={value}
          setValue={(answer) => {
            answerQuestion(label, answer);
          }}
          index={index}
        />
      );
    case "checkbox":
      return (
        <Checkbox
          question={question}
          value={value}
          setValue={(answer) => {
            answerQuestion(label, answer);
          }}
          index={index}
        />
      );
    default:
      return null;
  }
}

FormQuestion.propTypes = {
  question: propTypes.shape({
    type: propTypes.string,
    label: propTypes.string,
  }).isRequired,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.bool,
  ]).isRequired,
  index: propTypes.number.isRequired,
  answerQuestion: propTypes.func.isRequired,
};

function ScreenTime({ values }) {
  const noKeys = Object.keys(values).map((key) => values[key]);
  const daily = noKeys.reduce((sum, value) => sum + value);
  const yearly = daily * 365;
  return (
    <div className="screenTime">
      <div>
        <h3>Gesamtzeit digitale Medien</h3>
        <div className="screenTimeNumber">{`${daily} Stunden`}</div>
      </div>
      <div>
        <h3>Gesamtzeit digitale Medien (jährlich)</h3>
        <div className="screenTimeNumber">{`${yearly} Stunden`}</div>
      </div>
    </div>
  );
}

ScreenTime.propTypes = {
  values: propTypes.instanceOf(Object).isRequired,
};

function questionsToValues(questions, responses) {
  const values = {};
  questions.forEach(({ label, defaultValue }) => {
    values[label] =
      responses[label] !== undefined ? responses[label] : defaultValue;
  });
  return values;
}

const objectToArray = (object) => {
  if (!object) return [];
  if (Array.isArray(object)) return object;
  return Object.keys(object)
    .sort()
    .map((key) => object[key]);
};

function formatValues(results, section, responseSection) {
  if (results && results.values) return results.values;
  const { questions, key } = section;
  if (key === "empfehlungen") return responseSection;
  return questionsToValues(questions, responseSection);
}

function FormSection({ results, section, updateSection }) {
  const { label, questions, key } = section;
  const { responses } = useContext<any>(Context);
  const responseSection = responses[key] || {};
  const [values, setValues] = useState(
    formatValues(results, section, responseSection)
  );

  useEffect(() => {
    if (results) return;
    updateSection(key, { label, values });
  }, []);

  if (key === "empfehlungen") {
    return (
      <Empfehlungen
        savedArtikel={objectToArray(values)}
        updateSavedArtikel={(artikel) => {
          updateSection(key, { label, values: artikel });
        }}
      />
    );
  }

  const { type } = questions[0];

  const answerQuestion = (questionLabel, answer) => {
    values[questionLabel] = answer;
    setValues({ ...values });
    updateSection(key, { label, values });
  };

  const assembleHeader = () => {
    if (!Array.isArray(label)) return <h2>{label}</h2>;

    return (
      <h2>
        {label[0]}
        {label.slice(1).map((piece) => (
          <React.Fragment key={piece}>
            <br />
            {piece}
          </React.Fragment>
        ))}
      </h2>
    );
  };
  const sectionHeader = assembleHeader();
  return (
    <section>
      {sectionHeader}
      <form className={`form-${type}`}>
        {questions.map((question, index) => (
          <FormQuestion
            index={index}
            key={question.id ?? question.label}
            question={question}
            answerQuestion={answerQuestion}
            value={values[question.id ?? question.label]}
          />
        ))}
      </form>
      {key === "screenTime" && <ScreenTime values={values} />}
    </section>
  );
}

FormSection.propTypes = {
  //@ts-ignore
  section: propTypes.shape([]).isRequired,
  updateSection: propTypes.func.isRequired,
  results: propTypes.instanceOf(Object),
};
FormSection.defaultProps = { results: undefined };

export default FormSection;
