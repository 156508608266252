import React from "react";
import propTypes from "prop-types";

function Select({ question, value, setValue, index }) {
  const { label, values, readOnly } = question;
  const questionID = `${label}${index}`;
  const expandedValues = values.map((item) => {
    if (typeof item !== "object") return { id: item, name: item };
    const { id, name } = item;
    return { id, name };
  });

  return (
    <div className="inputWrapper select">
      <label htmlFor={questionID}>{label}</label>
      <br />
      <div className="selectWrapper">
        <select
          disabled={readOnly}
          id={questionID}
          value={value}
          onChange={({ target }) => {
            setValue(target.value);
          }}
        >
          {expandedValues.map(({ id, name }) => (
            <option
              className="option"
              key={id}
              value={id}
            >
              {name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

Select.propTypes = {
  question: propTypes.shape({
    readOnly: propTypes.bool,
    label: propTypes.string,
    values: propTypes.arrayOf(
      propTypes.oneOfType([
        propTypes.number,
        propTypes.string,
        propTypes.shape({
          id: propTypes.oneOfType([propTypes.number, propTypes.string]),
          name: propTypes.string,
        }),
      ])
    ),
  }).isRequired,
  value: propTypes.oneOfType([
    propTypes.number,
    propTypes.string,
    propTypes.shape({
      id: propTypes.oneOfType([propTypes.number, propTypes.string]),
      name: propTypes.string,
    }),
  ]).isRequired,
  setValue: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
};

export default Select;
