import React, { useContext } from "react";
import propTypes from "prop-types";

import Context from "../../ContextProvider";

import FormSection from "./FormSection";

function PersonalDetailsForm({ updateSection }) {
  const { form } = useContext<any>(Context);

  const [personalDetailsForm] = form.filter(
    ({ key }) => key === "personalDetails"
  );

  if (!personalDetailsForm) return null;
  return (
    <FormSection
      section={personalDetailsForm}
      updateSection={updateSection}
    />
  );
}
PersonalDetailsForm.propTypes = {
  updateSection: propTypes.func.isRequired,
};

export default PersonalDetailsForm;
