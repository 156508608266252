import React from "react";
import propTypes from "prop-types";

function Range({ question, value, setValue, index }) {
  const { label, values, readOnly } = question;
  const id = `${label}${index}`;
  const markersID = `${id}markers`;
  const min = 0;
  const max = values.length - 1;

  const progressBarStyle =
    value === max
      ? "100%"
      : `calc((100% / (${max + 1}) / 2) * (2 * ${value} + 1)) 100%`;

  return (
    <div className="inputWrapper range">
      <label htmlFor={id}>{label}</label>
      <br />
      <div className="rangeWrapper">
        <input
          disabled={readOnly}
          autoComplete="false"
          id={id}
          type="range"
          list={markersID}
          min={min}
          max={max}
          value={value}
          onChange={({ target }) => {
            setValue(Number(target.value));
          }}
          style={{ backgroundSize: progressBarStyle }}
        />
      </div>
      <div
        className="datalist"
        id={markersID}
      >
        {values.map((text, valIndex) => (
          <button
            className="option"
            key={text}
            value={valIndex}
            type="button"
            disabled={readOnly}
            onClick={() => {
              setValue(valIndex);
            }}
          >
            {text}
          </button>
        ))}
      </div>
    </div>
  );
}

Range.propTypes = {
  question: propTypes.shape({
    readOnly: propTypes.bool,
    label: propTypes.string,
    values: propTypes.arrayOf(propTypes.string),
  }).isRequired,
  value: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  setValue: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
};

export default Range;
