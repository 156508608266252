import React from 'react';
import propTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

function Checkbox({
  question,
  index,
  value,
  setValue,
}) {
  const { label, image, readOnly } = question;
  const id = `${label}${index}`;
  return (
    <label htmlFor={id} className={readOnly ? 'checkbox disabled' : 'checkbox'}>
      <input
        disabled={readOnly}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={() => { setValue(!value); }}
      />
      <div>
        <ReactSVG src={`/SVG/${image}`} />
        {label}
      </div>
    </label>
  );
}

Checkbox.propTypes = {
  question: propTypes.shape({
    label: propTypes.string,
    image: propTypes.string,
    readOnly: propTypes.bool,
  }).isRequired,
  value: propTypes.bool.isRequired,
  setValue: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
};

export default Checkbox;
