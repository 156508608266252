import React, { useEffect } from "react";
import {
  Outlet,
  BrowserRouter,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import "./App.css";

import { ContextProvider } from "./ContextProvider";

import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
import { VERSION } from "./constants/version";

function Content() {
  const loc = useLocation();
  console.log("Version " + VERSION);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loc, loc.pathname]);

  return (
    <ContextProvider>
      <Header />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </ContextProvider>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/:key?"
          element={<Content />}
        >
          <Route
            path=""
            element={<Body />}
          />
          <Route
            path="*"
            element={<Body />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
