import React from 'react';
import propTypes from 'prop-types';

function TextField({
  question,
  value,
  setValue,
  index,
}) {
  const { label, readOnly } = question;
  const id = `${label}${index}`;
  const markersID = `${id}markers`;

  return (
    <div className="inputWrapper text">
      <label htmlFor={id}>{label}</label>
      <br />
      <div className="textWrapper">
        <input
          disabled={readOnly}
          autoComplete="false"
          id={id}
          type="text"
          list={markersID}
          value={value}
          onChange={({ target }) => { setValue(target.value); }}
        />
      </div>
    </div>
  );
}

TextField.propTypes = {
  question: propTypes.shape({
    label: propTypes.string,
    readOnly: propTypes.bool,
  }).isRequired,
  value: propTypes.oneOfType([
    propTypes.number,
    propTypes.string,
  ]).isRequired,
  setValue: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
};

export default TextField;
