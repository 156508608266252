import React, { useEffect, useState } from "react";
import propTypes from "prop-types";

import CheckboxNormal from "./CheckboxNormal";

function MultipleSelect({ question, value, setValue, index, setPopup }) {
  const { label, readOnly, values } = question;
  const id = `${label}${index}`;
  const markersID = `${id}markers`;
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
    setPopup(null);
  };
  const openPopup = () => {
    setOpen(true);
    setPopup(
      <div className="multipleSelectPopup">
        <form>
          {values.map(({ id: valID, name }) => (
            <CheckboxNormal
              // id={valID} TODO didn't exist on component
              question={{ label: name }}
              value={value.includes(valID) || value.includes(String(valID))}
              setValue={() => {
                setValue(valID);
              }}
            />
          ))}
        </form>
        <button
          type="button"
          onClick={closePopup}
        >
          Bestätigen
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (open) openPopup();
  }, [JSON.stringify(value)]);

  return (
    <div className="inputWrapper text">
      <label htmlFor={id}>{label}</label>
      <br />
      <div className="textWrapper">
        <input
          disabled={readOnly}
          autoComplete="false"
          id={id}
          type="text"
          list={markersID}
          value={value
            .map(
              (val) => values.find((option) => `${option.id}` === `${val}`).name
            )
            .join(", ")}
          onClick={openPopup}
          onChange={({ target }) => {
            setValue(target.value);
          }}
        />
      </div>
    </div>
  );
}

MultipleSelect.propTypes = {
  setPopup: propTypes.func.isRequired,
  question: propTypes.shape({
    label: propTypes.string,
    readOnly: propTypes.bool,
    values: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.oneOfType([propTypes.number, propTypes.string]),
        name: propTypes.string,
      })
    ),
  }).isRequired,
  value: propTypes.arrayOf(
    propTypes.oneOfType([propTypes.number, propTypes.string])
  ),
  setValue: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
};
MultipleSelect.defaultProps = { value: [] };

export default MultipleSelect;
