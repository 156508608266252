import React, { createContext, useEffect, useMemo, useState } from "react";
import propTypes from "prop-types";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Buffer } from "buffer";

import BufferImage, { imageSrc } from "./components/BufferImage";
import rawForm, { mitarbeiterFormular } from "./formmodel";

import Loading from "./components/Loading/Loading";

//@ts-ignore
const Context = createContext();

function ContextProvider({ children }) {
  const backendURL =
    process.env.REACT_APP_AKUSTIK_BACKEND_URL || "http://localhost:3030";
  const [propertiesLoaded, setPropertiesLoaded] = useState(false);

  const [imageURL, setImageURL] = useState("SVG/garrels-aurich-logo-white.svg");
  const [logo, setLogo] = useState(
    <img
      src={imageURL}
      alt=""
    />
  );
  const [address, setAddress] = useState("Marktpassage 5, 26603 Aurich");
  const [telephone, setTelephone] = useState("04949416042240");
  const [email, setEmail] = useState("info@garrels-aurich.de");

  const [krankenkassen, setKrankenkassen] = useState([]);
  const [rabatt, setRabatt] = useState();
  const [hoersystemAnzeigen, setHoersystemAnzeigen] = useState(true);

  const [impressum, setImpressum] = useState("");
  const [datenschutz, setDatenschutz] = useState("");
  const [agb, setAGB] = useState("");
  const [hours, setHours] = useState("");

  const [searchParams] = useSearchParams();
  const [userKey] = useState(searchParams.get("key"));
  const [mode, setMode] = useState("");
  const [form, setForm] = useState([]);
  const [responses, setResponses] = useState<any>({});
  const [mitarbeiter, setMitarbeiter] = useState({});

  const [artikelOptions, setArtikelOptions] = useState({});
  const [optionenInfo, setOptionenInfo] = useState([]);

  const thereAreEmpfehlungen = () => {
    if (!responses) return false;
    if (!responses.empfehlungen) return false;
    if (typeof responses.empfehlungen !== "object") return false;
    if (!Object.keys(responses.empfehlungen).length) return false;
    return true;
  };

  useEffect(() => {
    axios
      .get(`${backendURL}/api/frontend/formMode`, { params: { key: userKey } })
      .then(({ data }) => {
        setMode(data.mode || "guest");
        if (mode === "guest") return;

        console.log("formMode:", mode);

        const responseKeys = Object.keys(data.responses);
        responseKeys.forEach((responseKey) => {
          if (
            responseKey === "empfehlungen" &&
            Array.isArray(responses[responseKey])
          ) {
            responses[responseKey] = [...data.responses[responseKey].values];
            return;
          }
          responses[responseKey] = {
            ...data.responses[responseKey].values,
          };
        });
        setResponses({ ...responses });

        if (data.mitarbeiter) {
          let formattedMitarbeiter = {
            label: "Ihr Berater",
            values: {
              Name: `${data.mitarbeiter.vorname} ${data.mitarbeiter.name}`,
              "E-Mail": data.mitarbeiter.email,
            },
          };
          if (data.mitarbeiter.telefonnummer) {
            formattedMitarbeiter.values["Telefon"] =
              data.mitarbeiter.telefonnummer;
          }
          if (data.mitarbeiter.handynummer) {
            formattedMitarbeiter.values["Mobil"] = data.mitarbeiter.handynummer;
          }
          setMitarbeiter(formattedMitarbeiter);
        }
        setRabatt(data.rabatt);

        if (data.artikelOptions) setArtikelOptions(data.artikelOptions);
        if (data.artikelOptionen) setOptionenInfo(data.artikelOptionen);
      })
      .catch(({ message }) => {
        setMode("guest");
        console.error(message);
      });
  }, []);

  useEffect(() => {
    if (!mode) return;
    console.log("mode:", mode);
    const shouldLockQuestions = thereAreEmpfehlungen();
    if (!shouldLockQuestions) {
      setForm(rawForm);
      return;
    }
    const newForm = JSON.parse(JSON.stringify(rawForm));
    // This disabled all fields when there are empfehlungen
    // newForm.forEach((section) => {
    //   const { questions } = section;
    //   questions.forEach((question, index) => {
    //     questions[index].readOnly = true;
    //   });
    // });
    setForm(newForm);
  }, [mode]);

  const sendResults = (results) => {
    if (!userKey) return;
    const endpoint = `${backendURL}/api/frontend/formResponse`;

    const kundeAnswers = {};
    const mitarbeiterAnswers = {};
    Object.keys(results).forEach((sectionKey) => {
      const mitarbeiterField = mitarbeiterFormular.find(
        ({ key: fieldKey }) => fieldKey === sectionKey
      );
      if (mitarbeiterField) {
        mitarbeiterAnswers[sectionKey] = results[sectionKey];
      } else {
        kundeAnswers[sectionKey] = results[sectionKey];
      }
    });
    axios
      .put(endpoint, {
        key: userKey,
        results: kundeAnswers,
        loesungen: mitarbeiterAnswers,
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const keyMap = {
    companyColorCode: "--color-primary",
    font: "--font-family",
    textColor: "--text-color",
    highlightColor: "--color-secondary",
  };
  const updateCustomProperties = (properties) => {
    Object.keys(properties).forEach((key) => {
      const value = properties[key];
      if (value === undefined) return;
      const propertyName = keyMap[key];
      const formattedValue =
        propertyName === "--font-family" ? `"${value}"` : value;

      switch (key) {
        case "showHearingSystems":
          setHoersystemAnzeigen(value);
          return;
        case "logo":
          setImageURL(value);
          setLogo(<img src={value} />);
          return;
        case "fullAddress":
          setAddress(value);
          return;
        case "telephone":
          setTelephone(value);
          return;
        case "email":
          setEmail(value);
          return;
        case "imprintLink":
          setImpressum(value);
          return;
        case "privacyPolicyLink":
          setDatenschutz(value);
          return;
        case "termsConditionsLink":
          setAGB(value);
          return;
        case "openingHours":
          setHours(value);
          return;
        case "krankenkassen":
          setKrankenkassen(value);
          return;
        case "name":
          document.title = `${value} | Garrels Akustik - App`;
          return;
        default:
          if (!propertyName) return;
          document.documentElement.style.setProperty(
            propertyName,
            formattedValue
          );
      }
    });
  };

  useEffect(() => {
    axios
      .get(`${backendURL}/api/frontend/properties`)
      .then(({ data }) => {
        setPropertiesLoaded(true);
        updateCustomProperties(data);
      })
      .catch(({ message }) => {
        console.error(message);
      });
  }, []);

  const contextValue = useMemo(
    () => ({
      imageURL,
      sendResults,
      address,
      telephone,
      email,
      form,
      responses,
      logo,
      impressum,
      datenschutz,
      agb,
      hours,
      krankenkassen,
      mode,
      userKey,
      artikelOptions,
      mitarbeiter,
      rabatt,
      hoersystemAnzeigen,
      optionenInfo,
      thereAreEmpfehlungen,
    }),
    [
      imageURL,
      address,
      telephone,
      email,
      form,
      responses,
      logo,
      impressum,
      datenschutz,
      agb,
      hours,
      krankenkassen,
      mode,
      hoersystemAnzeigen,
    ]
  );

  if (!mode || !propertiesLoaded) return <Loading />;
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

ContextProvider.propTypes = {
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
};
ContextProvider.defaultProps = { children: null };

export { ContextProvider };
export default Context;
