import React from "react";
import propTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function Checkbox({ question, index, value, setValue }) {
  let { label, readOnly } = question;
  const id = `${label}${index}`;
  if (question.label2) label = question.label2;
  return (
    <div className="inputWrapper text">
      <label
        htmlFor={id}
        className={readOnly ? "checkbox-normal disabled" : "checkbox-normal"}
      >
        <input
          disabled={readOnly}
          id={id}
          type="checkbox"
          value={value}
          checked={value}
          onChange={() => {
            setValue(!value);
          }}
        />
        <div className="check">
          <FontAwesomeIcon icon={faCheck} />
        </div>
        {label}
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  question: propTypes.shape({
    label: propTypes.string,
    image: propTypes.string,
    readOnly: propTypes.bool,
  }).isRequired,
  value: propTypes.bool.isRequired,
  setValue: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
};

export default Checkbox;
