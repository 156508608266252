import React from 'react';
import propTypes from 'prop-types';

import { mitarbeiterFormular } from '../../formmodel';

import FormSection from './FormSection';

function Evaluation({ results, updateSection }) {
  return (
    <>
      {mitarbeiterFormular.map((section) => (
        <FormSection
          key={section.label}
          results={results[section.key]}
          section={section}
          updateSection={updateSection}
        />
      ))}
    </>
  );
}

Evaluation.propTypes = {
  updateSection: propTypes.func.isRequired,
  results: propTypes.instanceOf(Object).isRequired,
};

export default Evaluation;
