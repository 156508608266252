const rangeSchlechtToGut = {
  type: "range",
  defaultValue: 2,
  values: ["sehr schlecht", "schlecht", "mittel", "gut", "sehr gut"],
};
const rangeZeroToMoreThanSix = {
  type: "range",
  defaultValue: 3,
  values: ["0 Std", "< 1", "bis 2", "bis 3", "bis 4", "bis 5", "bis 6", "> 6"],
};
const rangeStarkToOptimal = {
  type: "range",
  defaultValue: 0,
  values: ["stark eingeschränkt", "eingeschränkt", "optimal"],
};
const rangeAuffaellig = {
  type: "range",
  defaultValue: 0,
  values: ["auffällig", "unauffällig"],
};

const checkbox = { defaultValue: false, type: "checkbox" };

const currentHearing = {
  label: "Wie empfinden Sie ihr aktuelles Hörvermögen?",
  key: "currentHearing",
  questions: [
    { label: "Distanz", ...rangeSchlechtToGut },
    { label: "Nähe", ...rangeSchlechtToGut },
    { label: "Restaurant", ...rangeSchlechtToGut },
  ],
};
const screenTime = {
  label: [
    "Wie viele Stunden verbringen Sie täglich an Computer,",
    "Laptop / Tablet oder Smartphone?",
  ],
  key: "screenTime",
  questions: [
    { label: "TV", ...rangeZeroToMoreThanSix },
    { label: "Laptop", ...rangeZeroToMoreThanSix },
    { label: "Smartphone", ...rangeZeroToMoreThanSix },
  ],
};

const beschwerden1 = {
  label: "In welche der folgenden Situationen haben Sie Beschwerden?",
  key: "beschwerden1",
  questions: [
    {
      label: "Restaurant, Feiern, Geburtstage",
      image: "005-celebrate.svg",
      ...checkbox,
    },
    {
      label: "Fernsehen",
      image: "004-tv.svg",
      ...checkbox,
    },
    {
      label: "Verstehen im Auto",
      image: "006-car.svg",
      ...checkbox,
    },
    {
      label: "Einzelgespräch",
      image: "007-conversation.svg",
      ...checkbox,
    },
    {
      label: "Musik hören oder machen",
      image: "003-music.svg",
      ...checkbox,
    },
    {
      label: "Verstehen beim Einkaufen, Ansagen an der Kasse",
      image: "008-cash-register.svg",
      ...checkbox,
    },
    {
      label: "Beim Arzt",
      image: "002-doctor.svg",
      ...checkbox,
    },
    {
      label: "Beim Reisen",
      image: "009-travel.svg",
      ...checkbox,
    },
    {
      label: "Beim Fahrrad fahren",
      image: "010-bicycle.svg",
      ...checkbox,
    },
  ],
};
const beschwerden2 = {
  label: "In welche der folgenden Situationen haben Sie Beschwerden?",
  key: "beschwerden2",
  questions: [
    {
      label: "Telefonate",
      image: "001-call.svg",
      ...checkbox,
    },
    {
      label: "Vorträge",
      image: "005-lecture.svg",
      ...checkbox,
    },
    {
      label: "Konferenzen",
      image: "006-conference.svg",
      ...checkbox,
    },
    {
      label: "Einzelgespräche",
      image: "028-gesprach.svg",
      ...checkbox,
    },
    {
      label: "Gruppengespräche",
      image: "004-conversations.svg",
      ...checkbox,
    },
    {
      label: "Laute Umgebung",
      image: "007-noisy.svg",
      ...checkbox,
    },
  ],
};
const freizeit = {
  label: "Welche Freizeitaktivitäten üben Sie aus?",
  key: "freizeit",
  questions: [
    {
      label: "Rad fahren",
      image: "010-bicycle.svg",
      ...checkbox,
    },
    {
      label: "Ballsport",
      image: "008-sport.svg",
      ...checkbox,
    },
    {
      label: "Musizieren",
      image: "003-guitar.svg",
      ...checkbox,
    },
    {
      label: "Spazieren gehen, Joggen",
      image: "009-jogging.svg",
      ...checkbox,
    },
    {
      label: "Fitnessstudio",
      image: "002-gym-equipment.svg",
      ...checkbox,
    },
    {
      label: "Garten",
      image: "010-shovel.svg",
      ...checkbox,
    },
    {
      label: "Kirche",
      image: "001-church.svg",
      ...checkbox,
    },
    {
      label: "Kino, Konzerte",
      image: "011-kino.svg",
      ...checkbox,
    },
    {
      label: "Enkelkinder, Familie",
      image: "012-enkel.svg",
      ...checkbox,
    },
    {
      label: "Essen gehen, Freunde treffen",
      image: "013-essen.svg",
      ...checkbox,
    },
    {
      label: "sonstiges",
      image: "014-mehr.svg",
      ...checkbox,
    },
  ],
};
const beschwerden3 = {
  label: "Haben Sie folgende Beschwerden?",
  key: "beschwerden3",
  questions: [
    {
      label: "Diabetes",
      image: "015-diabetes.svg",
      ...checkbox,
    },
    {
      label: "Bluthochdruck",
      image: "016-blut.svg",
      ...checkbox,
    },
    {
      label: "Herzschrittmacher",
      image: "017-herzschlag.svg",
      ...checkbox,
    },
    {
      label: "Tinnitus",
      image: "018-auditiv.svg",
      ...checkbox,
    },
    {
      label: "Mittelohrentzündung",
      image: "019-ohr.svg",
      ...checkbox,
    },
    {
      label: "Motorik",
      image: "020-hallo.svg",
      ...checkbox,
    },
    {
      label: "Allergien z.B. Kunststoffe",
      image: "021-allergie.svg",
      ...checkbox,
    },
    {
      label: "Schwindel",
      image: "022-schwindel.svg",
      ...checkbox,
    },
  ],
};
const solutions = {
  label: "Lösungen für alle Ihre Hörsituationen",
  key: "solutions",
  questions: [
    {
      label: "360°Hören",
      image: "023-360-grad.svg",
      ...checkbox,
    },
    {
      label: "Bluetooth",
      image: "024-bluetooth.svg",
      ...checkbox,
    },
    {
      label: "Windgeräusche",
      image: "025-wind.svg",
      ...checkbox,
    },
    {
      label: "Akku",
      image: "026-akku-ladung.svg",
      ...checkbox,
    },
    {
      label: "Impulsschall",
      image: "027-geschirrspuler.svg",
      ...checkbox,
    },
    {
      label: "Sprachanhebung",
      image: "028-gesprach.svg",
      ...checkbox,
    },
    {
      label: "Musik",
      image: "003-music.svg",
      ...checkbox,
    },
    {
      label: "Automatikprogramm",
      image: "029-prozess.svg",
      ...checkbox,
    },
    {
      label: "Wasser und Staubschutz",
      image: "030-wasser.svg",
      ...checkbox,
    },
    {
      label: "TV-Set",
      image: "004-tv.svg",
      ...checkbox,
    },
    {
      label: "Störgeräuschunterdrückung",
      image: "007-noisy.svg",
      ...checkbox,
    },
    {
      label: "Fernbedienung App",
      image: "031-fernbedienung.svg",
      ...checkbox,
    },
    {
      label: "Zusätzliches Mikrofon",
      image: "032-mikrofon.svg",
      ...checkbox,
    },
    {
      label: "Signalanlagen",
      image: "033-signal.svg",
      ...checkbox,
    },
    {
      label: "Echoblock",
      image: "001-church.svg",
      ...checkbox,
    },
    {
      label: "Ästhetik",
      image: "034-diamant.svg",
      ...checkbox,
    },
  ],
};

type PersonalDetails = {
  label: string;
  key: string;
  questions: {
    label2?: string;
    label: string;
    type: string;
    defaultValue: string | boolean | number;
    requiredValue: "*" | boolean;
    values?: { id: string; name: string }[];
  }[];
};

const personalDetails: PersonalDetails = {
  label: "Kundendaten",
  key: "personalDetails",
  questions: [
    {
      label: "Anrede",
      type: "select",
      defaultValue: "Divers",
      values: [
        { id: "Herr", name: "Herr" },
        { id: "Frau", name: "Frau" },
        { id: "Divers", name: "Divers" },
      ],
      requiredValue: "*",
    },
    {
      label: "Vorname",
      type: "text",
      defaultValue: "",
      requiredValue: "*",
    },
    {
      label: "Nachname",
      type: "text",
      defaultValue: "",
      requiredValue: "*",
    },
    {
      label: "E-Mail",
      type: "text",
      defaultValue: "",
      requiredValue: "*",
    },
    {
      label: "Geburtsdatum",
      type: "date",
      defaultValue: "",
      requiredValue: "*",
    },
    {
      label: "Versicherungsart",
      type: "select",
      defaultValue: "gesetzlich",
      values: [
        { id: "gesetzlich", name: "Gesetzlich" },
        { id: "privat", name: "Privat" },
      ],
      requiredValue: "*",
    },
    {
      label: "Datenschutz",
      label2: `Ich stimme zu, dass meine Angaben und Daten zur Bearbeitung erhoben und gespeichert werden. Ihre Daten werden ausschließlich zur Eigennutzung erhoben und nicht an Dritte weitergegeben. Soweit Sie Ihre Einwilligung erteilt haben, werden Ihre Daten auch für die Zusendung der Ergebnisse und Informationen per E-Mail genutzt. Die Einwilligung kann jederzeit per E-Mail widerrufen werden.`,
      type: "checkbox-normal",
      defaultValue: false,
      requiredValue: true,
    },
  ],
};
const customerHearing = {
  label: "Ihr Hörvermögen",
  key: "customerHearing",
  questions: [
    { label: "Rechts", ...rangeStarkToOptimal },
    { label: "Links", ...rangeStarkToOptimal },
  ],
};
const customerTrommelfell = {
  label: "Ihr Trommelfell",
  key: "customerTrommelfell",
  questions: [
    { label: "Rechts", ...rangeAuffaellig },
    { label: "Links", ...rangeAuffaellig },
  ],
};
const customerGehoergang = {
  label: "Ihr Gehörgang",
  key: "customerGehoergang",
  questions: [
    { label: "Rechts", ...rangeAuffaellig },
    { label: "Links", ...rangeAuffaellig },
  ],
};

const empfehlungen = {
  label: "Artikelempfehlungen",
  key: "empfehlungen",
  questions: [],
};

const kundeFormular = [
  currentHearing,
  beschwerden1,
  screenTime,
  beschwerden2,
  freizeit,
  beschwerden3,
  personalDetails,
];
const mitarbeiterFormular = [
  customerHearing,
  customerTrommelfell,
  customerGehoergang,
  solutions,
  empfehlungen,
];
const jointForm = [...kundeFormular, ...mitarbeiterFormular];
export { mitarbeiterFormular, jointForm };
export default kundeFormular;
