import React, { useState, useContext, lazy, Suspense } from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { jointForm } from "../../formmodel";
import Context from "../../ContextProvider";

import Loading from "../Loading/Loading";

import PersonalDetailsForm from "./PersonalDetailsForm";
import CustomerDiagnosticForm from "./CustomerDiagnosticForm";
import Evaluation from "./Evaluation";

const ConfirmationPage = lazy(() => import("./ConfirmationPage"));

const pages = [
  { key: "personalDetails", text: "Persönliche Daten" },
  { key: "form", text: "Formular" },
  { key: "evaluation", text: "Auswertung" },
  { key: "end", text: "Formular unverbindlich absenden" },
];
function Content({ page, results, updateSection, availablePages, setPDF }) {
  switch (availablePages[page].key) {
    case "form":
      return (
        <CustomerDiagnosticForm
          results={results}
          updateSection={updateSection}
        />
      );
    case "evaluation":
      return (
        <Evaluation
          results={results}
          updateSection={updateSection}
        />
      );
    case "end":
      return (
        <Suspense fallback={<Loading />}>
          <ConfirmationPage
            results={results}
            setPDF={setPDF}
          />
        </Suspense>
      );
    case "personalDetails":
    default:
      return <PersonalDetailsForm updateSection={updateSection} />;
  }
}
Content.propTypes = {
  page: propTypes.number.isRequired,
  results: propTypes.instanceOf(Object).isRequired,
  updateSection: propTypes.func.isRequired,
  availablePages: propTypes.arrayOf(
    propTypes.shape({
      key: propTypes.string,
      text: propTypes.string,
    })
  ).isRequired,
  setPDF: propTypes.func.isRequired,
};

const rabattTooltip =
  "Bitte in der Kundentabelle eine Krankenasse für diesen Kunde eingeben.";
const fieldsTooltip = "Bitte alle erforderliche Felder ausfüllen.";

function PageButtons({
  goBack,
  goForwards,
  page,
  requiredFieldsFilled,
  userKey,
  availablePages,
  rabatt,
  pdf,
  fileName,
}) {
  const needsRabatt = (availablePages[page + 1] || {}).key === "evaluation";
  const validRabatt = !needsRabatt || rabatt !== null;

  const canProgress = requiredFieldsFilled && validRabatt;
  let errorMessage;
  if (!validRabatt) errorMessage = rabattTooltip;
  if (!requiredFieldsFilled) errorMessage = fieldsTooltip;

  const query = userKey ? `?key=${userKey}` : "";
  const pageButtons = (
    <section className="bottom-buttons">
      {availablePages[page - 1] ? (
        <Link to={`${availablePages[page - 1].key}${query}`}>
          <button
            key={availablePages[page - 1].key}
            type="button"
            onClick={goBack}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            {"   "}
            Zurück
          </button>
        </Link>
      ) : (
        <div />
      )}
      {availablePages[page + 1] ? (
        <Link to={`${availablePages[page + 1].key}${query}`}>
          <button
            key={availablePages[page + 1].key}
            type="button"
            onClick={goForwards}
            disabled={!canProgress}
            title={errorMessage}
          >
            Weiter
            {"   "}
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
          {/* {JSON.stringify({
            requiredFieldsFilled,
            validRabatt,
            needsRabatt,
            rabatt,
          })} */}
        </Link>
      ) : (
        <div />
      )}
      {!availablePages[page + 1] && pdf && (
        <PDFDownloadLink
          document={pdf}
          fileName={fileName}
        >
          <button type="button">
            Ergebnisse herunterladen
            {"   "}
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </PDFDownloadLink>
      )}
    </section>
  );
  return pageButtons;
}
PageButtons.propTypes = {
  goBack: propTypes.func.isRequired,
  goForwards: propTypes.func.isRequired,
  page: propTypes.number.isRequired,
  requiredFieldsFilled: propTypes.bool.isRequired,
  userKey: propTypes.string,
  availablePages: propTypes.arrayOf(
    propTypes.shape({
      key: propTypes.string,
      text: propTypes.string,
    })
  ).isRequired,
  rabatt: propTypes.number,
  pdf: propTypes.node,
  fileName: propTypes.string.isRequired,
};
PageButtons.defaultProps = {
  userKey: "",
  rabatt: 0,
  pdf: undefined,
};

function areRequiredFieldsFilled(personalDetailsForm, results) {
  if (!personalDetailsForm) return true;
  const requiredFields = personalDetailsForm.questions.filter(
    ({ requiredValue }) => requiredValue !== undefined
  );

  const answerSatisfactory = ({ label, requiredValue }) => {
    if (!results) return false;
    if (!results.personalDetails) return false;
    if (!results.personalDetails.values) return false;
    const actualValue = results.personalDetails.values[label];
    const hasValue =
      actualValue !== undefined && actualValue !== null && actualValue !== "";
    if (requiredValue === "*" && hasValue) return true;
    return requiredValue === actualValue;
  };

  const missingAnswers = requiredFields
    .map((question) => answerSatisfactory(question))
    .filter((matches) => matches === false);
  return missingAnswers.length === 0;
}

function initialResults(responses) {
  const results = {};
  const keys = Object.keys(responses);
  keys.forEach((key) => {
    const formSection = jointForm.find(({ key: formKey }) => formKey === key);
    const { label } = formSection;
    const values = responses[key];
    results[key] = { label, values };
  });
  return results;
}

function Body() {
  const { userKey, form, mode, rabatt, responses } = useContext<any>(Context);
  const [page, setPage] = useState(0);
  const [results, setResults] = useState<any>(initialResults(responses));
  const [pdf, setPDF] = useState();
  const availablePages = pages.filter(({ key }) => {
    if (mode === "mitarbeiter") return true;
    return key !== "evaluation";
  });

  const updateSection = (section, values) => {
    results[section] = values;
    setResults({ ...results });
  };

  const goBack = () => {
    setPage(page - 1);
  };
  const goForwards = () => {
    setPage(page + 1);
  };

  const [personalDetailsForm] = form.filter(
    ({ key }) => key === "personalDetails"
  );

  const getName = () => {
    if (!results) return [];
    if (!results.personalDetails) return [];
    if (!results.personalDetails.values) return [];
    return [
      results.personalDetails.values.Vorname,
      results.personalDetails.values.Nachname,
    ];
  };
  const [vorname, nachname] = getName();
  const fileName = ["Höranalyse", vorname, nachname]
    .filter((part) => part)
    .join("_");

  const requiredFieldsFilled =
    areRequiredFieldsFilled(personalDetailsForm, results) && userKey;
  if (!personalDetailsForm) return null;

  return (
    <>
      <Content
        page={page}
        results={results}
        updateSection={updateSection}
        availablePages={availablePages}
        setPDF={setPDF}
      />
      <PageButtons
        goBack={goBack}
        goForwards={goForwards}
        page={page}
        requiredFieldsFilled={requiredFieldsFilled}
        userKey={userKey}
        availablePages={availablePages}
        rabatt={rabatt}
        pdf={pdf}
        fileName={fileName}
      />
    </>
  );
}

export default Body;
